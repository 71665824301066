<div>
    <div class="d-flex justify-content-start sticky-header py-2">
        <a [href]="goToEvents()" target="_blank">
            <h3 class="mb-0"><span class="badge badge-dark">{{ company.name }}</span></h3>
        </a>
        <div class="ml-auto">
            <div class="chip chip-inverse chip-link" (click)="addNewEvent()" *ngIf="hasPermission">
                <i class="fa fa-plus"></i>
                Add New Event
            </div>
            <div class="chip chip-inverse">
                <con-avatar [userId]="company?.manager_id" [size]="32"></con-avatar>
                {{ company.manager?.first_name }} {{ company.manager?.last_name }}
            </div>
        </div>
    </div>
    <con-loader *ngIf="loading"></con-loader>
    <div *ngIf="!hasPermission && !loading" class="alert alert-danger mt-2">
        {{permissionErrorMessage}}
    </div>
    <div *ngIf="!loading && hasPermission">
        <ng-container *ngFor="let event of company.newEvents; let i = index">
        <div class="row">
            <div class="col-12">
                <div class="card mb-2">
                    <div class="card-header" (click)="toggleType(event)">
                        <div class="d-flex justify-content-start align-items-center">
                            {{event.currentEditingEvent ? 'Edit Event' : 'New Event'}}
                            <div class="card-header-toggle-individual ml-auto">
                                <div class="chip chip-link add-comment" *ngIf="event.currentEditingEvent" (click)="toggleEdit(event, $event)">
                                    <i class="fa fa-pencil fa-fw"></i>
                                    Edit
                                </div>
                                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(event)"></con-icon-by-name>     
                                <con-icon-by-name ngbTooltip="Remove Window" iconName="remove" (click)="removeEvent(i)"></con-icon-by-name>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="event.expanded">
                                <con-custom-entity-form-content *ngIf="!event.currentEditingEvent"
                                            [ownerType]="ownerType"
                                            [showEditButton]="false" 
                                            entityName="CalendarEvent"
                                            [fixedValues]="{ direkt_id: null, revised_at: now, owner_id: company.id, owner_type: ownerType }"
                                            [emptyAfterSave]="true"
                                            (afterSave)="newEventChanged($event, event)"
                                            (showComments)="setCommentFlag($event, event)"
                                            [hasFixedCalendarValues]="true"
                                            [addCommentSection]="true"
                                            [initialSaveEnabled]="false"
                                            toastMessage="Added successfully!"
                                            [preFillValues]="{ from_date: tomorrow }"
                                            [relationValues]="{ owner: company }"
                                            [enableRecurringEvent]="true"
                                            [setEntityFormContent]="false"></con-custom-entity-form-content>
                                <con-custom-entity-form-content *ngIf="event.currentEditingEvent"
                                            [ownerType]="ownerType"
                                            entityName="CalendarEvent"
                                            showEditButton=true 
                                            [entity]="event.currentEditingEvent"
                                            [fixedValues]="{ direkt_id: null, revised_at: now, owner_id: company.id, owner_type: ownerType }"
                                            [emptyAfterSave]="true"
                                            (afterSave)="newEventChanged($event, event)"
                                            (afterDelete)="newEventDeleted()"
                                            toastMessage="Updated successfully!"
                                            [hasFixedCalendarValues]="true"
                                            (afterDirectProfileChange)="direktProfileChanged($event, event.currentEditingEvent.id)"
                                            (showComments)="setCommentFlag($event, event)"
                                            [enableRecurringEvent]="true"
                                            [setEntityFormContent]="false"></con-custom-entity-form-content>
                            <div *ngIf="event.currentEditingEvent" class="mt-2">
                                <ng-container *ngIf="event?.currentEditingEvent?.isLoadingComments">
                                    <con-loader></con-loader>
                                </ng-container>
                                <ng-container *ngIf="!event?.currentEditingEvent?.isLoadingComments">
                                    <div class="alert alert-info clearfix mt-2" *ngFor="let comment of event.currentEditingEvent.comments">
                                        <div class="float-left text-left" *ngIf="!isEditingComment(comment,event.currentEditingEvent)">
                                            {{ comment.comment }} ({{ comment.language.iso639_2 }})
                                        </div>
                                        <con-entity-form-content
                                            *ngIf="isEditingComment(comment, event.currentEditingEvent)"
                                            entityName="CalendarEventComment"
                                            [entity]="comment"
                                            [fixedValues]="{ calendar_event_id: event.currentEditingEvent.id, language_id: comment.language_id }"
                                            (afterSave)="commentUpdated($event, event.currentEditingEvent)"
                                            (afterDelete)="commentDeleted($event, event.currentEditingEvent)"
                                            toastMessage="Updated successfully!"
                                            [setEntityFormContent]="false"
                                        ></con-entity-form-content>
                                        <div class="float-right">
                                            <con-icon-by-name iconName="pencil" (click)="toggleEditOfComment(comment, event.currentEditingEvent)"></con-icon-by-name>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="border p-3" *ngIf="event.currentEditingEvent.id">
                                    <con-entity-form-content
                                        entityName="CalendarEventComment"
                                        [entity]="{ language: commentLangDefault, language_id: commentLangDefault.id}"
                                        [preFillValues]=" { language_id: commentLangDefault.id}"
                                        [fixedValues]="{ calendar_event_id: event.currentEditingEvent.id }"
                                        [emptyAfterSave]="true"
                                        (afterSave)="commentAdded($event, event.currentEditingEvent, true)"
                                        [direktProfileLanguage]="{ eventId: event.currentEditingEvent.id, language: newEventDirectProfileLangs }"
                                        toastMessage="Added successfully!"
                                        [setEntityFormContent]="false"
                                    >
                                    </con-entity-form-content>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
        <div class="card mb-2" *ngFor="let t of company.missing_event_types; let i = index">
            <div class="card-header" (click)="toggleType(t)">
                <div class="d-flex justify-content-start align-items-center">
                    <con-icon-by-name iconName="calendar" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
                    {{ t.name }}    
                    <div class="card-header-toggle-individual ml-auto">
                        <div (click)="toggleEdit(t, $event)" class="chip chip-link mr-2" *ngIf="t.missing_event">
                            <i class="fa fa-pencil fa-fw"></i>
                            Edit
                        </div>
                        <con-icon-by-name iconName="angle-right" [mutations]="getMutations(t)"></con-icon-by-name>
                        <con-icon-by-name *ngIf="t.missing_event" ngbTooltip="Remove Event" iconName="remove" (click)="removeMissingEvent(i)"></con-icon-by-name>
                    </div>
                </div>
            </div>
            <div class="card-body" *ngIf="t.expanded">
                <con-custom-entity-form-content *ngIf="!t.missing_event"
                    [showEditButton]="false"
                    entityName="CalendarEvent"
                    (afterSave)="eventCreated($event)"
                    (showComments)="setCommentFlag($event, t)"
                    [hasFixedCalendarValues]="true"
                    [addCommentSection]="true"
                    [initialSaveEnabled]="false"
                    [preFillValues]="{ from_date: tomorrow }"
                    [fixedValues]="{ calendar_event_type_id: t.id, owner_id: company.id, owner_type: ownerType, revised_at: now }"
                    [relationValues]="{ calendarEventType: t, owner: company }"
                    [enableRecurringEvent]="true"
                    [setEntityFormContent]="false">
                </con-custom-entity-form-content>
                <con-custom-entity-form-content *ngIf="t.missing_event"
                    showEditButton=true
                    [entity]="t.missing_event"
                    entityName="CalendarEvent"
                    (afterSave)="eventCreated($event)"
                    (showComments)="setCommentFlag($event, t)"
                    [hasFixedCalendarValues]="true"
                    (afterDirectProfileChange)="direktProfileChanged($event, t.missing_event.id)"
                    (afterDelete)="afterDeletingEvent(t)"
                    [fixedValues]="{ calendar_event_type_id: t.id, owner_id: company.id, owner_type: ownerType, revised_at: now }"
                    [setEntityFormContent]="false"
                    [enableRecurringEvent]="true">
                </con-custom-entity-form-content>
                <div  *ngIf="t.missing_event" class="mt-2">
                    <ng-container *ngIf="t?.missing_event?.isLoadingComments">
                        <con-loader></con-loader>
                    </ng-container>
                    <ng-container *ngIf="!t?.missing_event?.isLoadingComments">
                        <div class="alert alert-info clearfix" *ngFor="let comment of t.missing_event.comments">
                            <div class="float-left text-left" *ngIf="!isEditingComment(comment)">
                                {{ comment.comment }} ({{ comment.language.iso639_2 }}) 
                            </div>
                            <con-entity-form-content
                                *ngIf="isEditingComment(comment, t.missing_event)"
                                entityName="CalendarEventComment"
                                [entity]="comment"
                                [fixedValues]="{ calendar_event_id: t.missing_event.id, language_id: comment.language_id }"
                                (afterSave)="commentUpdated($event, t.missing_event)"
                                (afterDelete)="commentDeleted($event, t.missing_event)"
                                toastMessage="Updated successfully!"
                                [setEntityFormContent]="false"
                            ></con-entity-form-content>
                            <div class="float-right">
                                <con-icon-by-name iconName="pencil" (click)="toggleEditOfComment(comment, t.missing_event)"></con-icon-by-name>
                            </div>
                        </div>
                    </ng-container>
                    <div class="border p-3" *ngIf="t.missing_event.id">
                        <con-entity-form-content
                            entityName="CalendarEventComment"
                            [entity]="{ language: commentLangDefault, language_id: commentLangDefault.id}"
                            [preFillValues]=" { language_id: commentLangDefault.id}"
                            [fixedValues]="{ calendar_event_id: t.missing_event.id }"
                            [emptyAfterSave]="true"
                            (afterSave)="commentAdded($event, t.missing_event, true)"
                            [direktProfileLanguage]="{ eventId: t.missing_event.id, language: newEventDirectProfileLangs }"
                            toastMessage="Added successfully!"
                            [setEntityFormContent]="false"
                        >
                        </con-entity-form-content>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body" *ngIf="!company.missing_event_types.length">
            <span class="lead">
                No missing events for this {{ownerType.toLowerCase()}} !
            </span>
        </div>
    </div>
    <hr *ngIf="hasPermission">
</div>